import React, { ReactElement, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowsRotate,
  faCalendar,
  faCheckCircle,
  faCalendarDays,
  faEllipsisH,
  faFlask,
  faGaugeMax,
  faLightbulbOn,
  faProjectDiagram,
  faUserFriends,
  faShareNodes,
  faCube,
  faTrashCanCheck,
  faShop,
  faRecycle,
} from '@fortawesome/pro-regular-svg-icons'
import { DateTime, Interval } from 'luxon'
import {
  IWGenericCard,
  IWGenericCardBody,
  IWGenericCardFooter,
  IWGenericCardHeader,
} from 'shared/components/thunderbolt/IWGenericCard'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import IWCardBadge from 'shared/components/thunderbolt/IWCardBadge'
import IWGeneralBadge from 'shared/components/thunderbolt/IWGeneralBadge'
import IWBadgeGroup from 'shared/components/thunderbolt/IWBadgeGroup'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWButtonGroup from 'shared/components/thunderbolt/IWButtonGroup'
import IWContextMenu from 'shared/components/thunderbolt/IWContextMenu'
import UserContext from 'shared/contexts/UserContext'
import IWContextMenuItem from 'shared/components/thunderbolt/IWContextMenuItem'
import useDurationHumanizer from 'shared/hooks/useDurationHumanizer'
import { Link } from 'react-router-dom'
import { OrgUser } from 'shared/userServiceClient'
import { Hue } from '../../../@types/styled'
import { Pod, SharingRole } from '../types'
import IWContextDivider from 'shared/components/thunderbolt/IWContextMenuItemDivider'
import LayoutContext from 'shared/contexts/LayoutContext'

type Props = {
  /** Pod object  */
  pod: Pod
  /** Map of userIds to user object  */
  orgUsersMap: { [userId: string]: OrgUser }
  /** Handler to call when deleting a pod  */
  onDeletePod: (userPodId) => void
  /** Handler to call when sharing a pod  */
  onSharePod: (userPodId) => void
  /** Handler to call when getting the data cube for a pod  */
  onGetPodDataCube: (userPodId) => void
  /** update pod to latest version of sql */
  onUpdate: (userPodId) => void
  /** Boolean loading flag * */
  isLoading?: boolean
}

const StyledIWGenericCardHeader = styled(IWGenericCardHeader)`
  display: flex;
  align-items: center;
`

const StyledIWGenericCardInfo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.25rem;
  flex: 1;
`
const StyledHeaderBadge = styled(FontAwesomeIcon)`
  align-self: center;
  color: ${(props) => props.theme.palette.secondary[700]};
`

const StatusBadgeWrapper = styled.div`
  align-self: center;
`

const StyledGenericCardBody = styled(IWGenericCardBody)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const StyledIWGenericCardFooter = styled(IWGenericCardFooter)`
  padding: 1rem 1.5rem;
  background: ${(props) => props.theme.palette.grey[100]};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.palette.primary[700]};
  font-size: ${(props) => props.theme.typography.fontSizes.base};
  font-weight: ${(props) => props.theme.typography.fontWeights.medium};

  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

/**
 * A card to display the details of a pod in the app. Contained in an IWPodCardList
 */
const PodCard = ({
  pod: {
    userId,
    userPodName,
    userPodId,
    podType,
    interval,
    createdAt,
    lastRun,
    nextRun,
    currentStatus,
    hasBeenSuccessful,
    sharedWithOrgRole,
    sharedWithUsers,
    sharedByUserId,
    useForLoadScheduling,
    myRole,
    isDefault,
    markets,
  },
  orgUsersMap,
  onDeletePod,
  onSharePod,
  onGetPodDataCube,
  onUpdate,
  isLoading,
}: Props) => {
  const { insightId, metricId } = podType
  const { t, i18n } = useTranslation()
  const { userId: currentUserId, organization } = useContext(UserContext)
  const [sinceLastRun, setSinceLastRun] = useState<string>()
  const activeLanguage = i18n.language
  const { humanizer } = useDurationHumanizer({ language: activeLanguage })
  const { timezone } = useContext(LayoutContext)

  const isPodOwner = userId === currentUserId // only pod owners
  const canSharePod =
    isPodOwner || (myRole && (myRole as SharingRole) >= SharingRole.EDIT)

  useEffect(() => {
    if (lastRun) {
      const duration = Interval.fromDateTimes(
        DateTime.fromISO(lastRun, {
          zone: timezone,
        }),
        DateTime.now(),
      )
        .toDuration()
        .toMillis()
      // Calculate the localised age of the pod
      setSinceLastRun(humanizer(duration))
    }
  }, [lastRun, activeLanguage, humanizer, timezone])

  /**
   * Button group which renders in the footer when there is existing output data
   */
  const renderFooterButtons = () => {
    return (
      <IWButtonGroup variant="outline" color="grey" hasSolidBackground>
        <IWButton
          data-testid="pod-actions-explore"
          icon={faFlask}
          disabled={isLoading}
          iconPosition="leading"
          onClick={onGetPodDataCube}
        >
          {t('insightManager.pods.actions.explore')}
        </IWButton>
      </IWButtonGroup>
    )
  }

  /**
   * Renders the content menu, if the pod has data it renders an explore and divider
   */
  const renderContextMenu = () => {
    const contextMenuHeader: ReactElement[] = []

    if (hasBeenSuccessful) {
      contextMenuHeader.push(
        <IWContextMenuItem
          key="explore"
          data-testid="pod-actions-explore"
          label={t('insightManager.pods.actions.explore')}
          icon={faFlask}
          onClick={onGetPodDataCube}
        />,
      )

      if (canSharePod) {
        contextMenuHeader.push(
          <IWContextMenuItem
            key="share"
            icon={faShareNodes}
            data-testid="pod-actions-share"
            label={t('insightManager.pods.actions.share')}
            onClick={onSharePod}
          />,
        )
      }
      contextMenuHeader.push(<IWContextDivider />)
    }

    contextMenuHeader.push(
      <IWContextMenuItem
        as="routerLink"
        key="details"
        icon={faCube}
        data-testid="pod-actions-details"
        label={t('insightManager.pods.actions.details')}
        to={`/tools/insights-manager/pod-details/${userPodId}`}
      />,
    )

    if (useForLoadScheduling) {
      contextMenuHeader.push(
        <IWContextMenuItem
          as="routerLink"
          key="load-scheduling-link"
          icon={faCalendarDays}
          data-testid="pod-actions-load-scheduling-link"
          label={t('insightManager.pods.actions.loadScheduling')}
          to={`/tools/load-scheduling/submit?podId=${userPodId}`}
        />,
      )
    }

    if (isPodOwner) {
      contextMenuHeader.push(
        <IWContextMenuItem
          key="delete"
          icon={faTrashCanCheck}
          data-testid="pod-actions-delete"
          label={t('insightManager.pods.actions.delete')}
          onClick={onDeletePod}
        />,
        <IWContextMenuItem
          key="update"
          icon={faRecycle}
          data-testid="pod-actions-update"
          label={t('insightManager.pods.actions.update')}
          onClick={onUpdate}
        />,
      )
    }

    return [...contextMenuHeader]
  }

  const renderStatusBadge = (): ReactElement | undefined => {
    let hue: Hue = { color: 'primary', value: 700 }
    switch (currentStatus) {
      case 'initializing':
        hue = { color: 'grey', value: 500 }
        break
      case 'ready':
        hue = { color: 'secondary', value: 900 }
        break
      case 'running':
        hue = { color: 'warning', value: 900 }
        break
      case 'failed':
        hue = { color: 'alert', value: 800 }
        break
      default:
        return undefined
    }
    return (
      <IWGeneralBadge
        data-testid="pod-status-badge"
        label={t(`insightManager.pods.status.badge.${currentStatus}`)}
        fontHue={{ color: 'grey', value: 0 }}
        hue={hue}
      />
    )
  }

  const renderSharedWith = () => {
    const sharedOrgsOtherThanUserOrg = sharedWithOrgRole?.filter(
      ({ orgId }) => orgId !== organization,
    )
    if (sharedWithOrgRole && sharedWithOrgRole.length > 0) {
      return (
        <IWBadgeGroup>
          <IWCardBadge
            label={`${t(
              'insightManager.pods.sharing.sharedWithMyOrganization',
            )} 
            ${
              sharedOrgsOtherThanUserOrg &&
              sharedOrgsOtherThanUserOrg.length > 0
                ? ` +${sharedOrgsOtherThanUserOrg.length} Org`
                : ''
            }
            ${
              sharedWithUsers && sharedWithUsers.length > 0
                ? ` +${sharedWithUsers.length}`
                : ''
            }`}
            icon={faUserFriends}
          />
        </IWBadgeGroup>
      )
    }
    if (
      (!sharedWithOrgRole || sharedWithOrgRole.length <= 0) &&
      sharedWithUsers &&
      sharedWithUsers.length > 0
    ) {
      return (
        <IWBadgeGroup>
          <IWCardBadge
            label={`${t('insightManager.pods.sharing.sharedWith', {
              user:
                orgUsersMap[sharedWithUsers[0].userId]?.name ||
                t('insightManager.pods.sharing.unknownUser'),
            })}${
              sharedWithUsers.length >= 2
                ? ` +${sharedWithUsers.length - 1}`
                : ''
            }`}
            icon={faUserFriends}
          />
        </IWBadgeGroup>
      )
    }

    return ''
  }

  function buildIntervalLabel() {
    if (!interval) {
      return t('insightManager.pods.interval.never')
    }
    if (!nextRun) {
      return t('insightManager.pods.interval.nextRun')
    }
    return t('insightManager.pods.interval.nextRun', {
      value: DateTime.fromISO(nextRun, {
        zone: timezone,
      }).toLocaleString(DateTime.DATE_SHORT),
    })
  }

  function renderOrgLabel(
    sharedOrgList: Array<{ orgId: string; role: number }>,
  ) {
    const isCurrentUserOrgPresentInSharedList = sharedOrgList?.find(
      ({ orgId }) => orgId === organization,
    )

    const isSharedByOrgShared = sharedOrgList?.find(
      ({ orgId }) => orgId === 'org_shared' || orgId === 'org_nonprod',
    )

    return isCurrentUserOrgPresentInSharedList && isSharedByOrgShared
      ? t('insightManager.pods.sharing.innowatts')
      : t('insightManager.pods.sharing.myOrganization')
  }

  const renderUpdateBadge = (): ReactElement | undefined => {
    const hue: Hue = { color: 'secondary', value: 500 }
    return (
      <IWGeneralBadge
        data-testid="pod-status-badge"
        label={'Updating'}
        fontHue={{ color: 'grey', value: 0 }}
        hue={hue}
      />
    )
  }

  return (
    <IWGenericCard data-testid="pod-card">
      <StyledIWGenericCardHeader>
        <StyledIWGenericCardInfo>
          <StyledLink
            to={`/tools/insights-manager/pod-details/${userPodId}`}
            data-testid="pod-name"
          >
            {userPodName}
          </StyledLink>

          {isDefault && (
            <StyledHeaderBadge
              data-testid="pod-default-badge"
              icon={faCheckCircle}
            />
          )}
          <StatusBadgeWrapper>
            {currentStatus && renderStatusBadge()}
          </StatusBadgeWrapper>
          <IWTypography size="xs" fontHue={{ color: 'grey', value: 0 }}>
            {userPodId}
          </IWTypography>
        </StyledIWGenericCardInfo>
        <IWContextMenu menu={renderContextMenu()} positions={['bottom']}>
          <IWButton
            variant="outline"
            color="grey"
            data-testid="pod-context-menu-button"
          >
            <FontAwesomeIcon icon={faEllipsisH} />
          </IWButton>
        </IWContextMenu>
      </StyledIWGenericCardHeader>
      <StyledGenericCardBody>
        <IWBadgeGroup>
          {Boolean(markets.length > 0) ? (
            <IWCardBadge
              label={markets.join(', ').toLocaleUpperCase()}
              icon={faShop}
            />
          ) : (
            <></>
          )}
          <IWCardBadge
            label={t(`insights.${insightId}.label`)}
            icon={faLightbulbOn}
          />
          <IWCardBadge
            label={t(`insights.${insightId}.metrics.${metricId}.label`)}
            icon={faGaugeMax}
          />
          {Boolean(useForLoadScheduling) ? (
            <IWCardBadge
              label={t(`insights.loadScheduling.label`)}
              icon={faCalendarDays}
            />
          ) : (
            <></>
          )}
          <IWCardBadge label={buildIntervalLabel()} icon={faArrowsRotate} />
        </IWBadgeGroup>

        <IWBadgeGroup testId="pod-date-strings">
          <IWCardBadge
            label={t('insightManager.pods.createdOn', {
              value: DateTime.fromISO(createdAt, {
                zone: timezone,
              }).toLocaleString(DateTime.DATE_SHORT),
            })}
            icon={faCalendar}
          />
          <IWCardBadge
            label={
              lastRun && sinceLastRun
                ? t('insightManager.pods.lastRanRelative', {
                    value: sinceLastRun?.split(' ')[0],
                  })
                : t('insightManager.pods.hasNeverRun')
            }
            icon={faCalendar}
          />
        </IWBadgeGroup>

        {sharedByUserId && (
          <IWBadgeGroup>
            <IWCardBadge
              label={t('insightManager.pods.sharing.sharedWithMeBy', {
                user:
                  sharedWithOrgRole && sharedWithOrgRole.length > 0
                    ? renderOrgLabel(sharedWithOrgRole)
                    : orgUsersMap[sharedByUserId]?.name ||
                      t('insightManager.pods.sharing.unknownUser'),
              })}
              icon={faProjectDiagram}
            />
          </IWBadgeGroup>
        )}
        {canSharePod && renderSharedWith()}
      </StyledGenericCardBody>

      {hasBeenSuccessful && (
        <StyledIWGenericCardFooter>
          {renderFooterButtons()}
        </StyledIWGenericCardFooter>
      )}
    </IWGenericCard>
  )
}

export default PodCard
