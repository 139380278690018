import {
  faCheck,
  faExclamation,
  faExclamationCircle,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import IWBadgeGroup from 'shared/components/thunderbolt/IWBadgeGroup'
import IWButton from 'shared/components/thunderbolt/IWButton'
import IWContextDivider from 'shared/components/thunderbolt/IWContextMenuItemDivider'
import IWFormSection from 'shared/components/thunderbolt/IWFormSectionContainer'
import IWGeneralBadge from 'shared/components/thunderbolt/IWGeneralBadge'
import IWLoading from 'shared/components/thunderbolt/IWLoading'
import IWNoResultsMessage from 'shared/components/thunderbolt/IWNoResultsMessage'
import { IWFileInput } from 'shared/components/thunderbolt/IWTextInput'
import { useToast } from 'shared/components/thunderbolt/IWToastContext'
import { TIMEZONES } from 'shared/constants'
import { convertToCVS, downloadContent } from 'shared/helpers'
import useBoundQueryParams from 'shared/hooks/useBoundQueryParams'
import { getPodList } from 'shared/podServiceClient'
import { WizardStepProps } from 'shared/types'
import styled from 'styled-components'
import { Pod } from '../../insightsManager/types'
import {
  convertLsCsvToJson,
  getErcotDayAheadBidsConvertSchema,
  getLsData,
  isErcotMarket,
} from '../helpers'
import { getDayAheadBidsConvertSchema } from '../schemas'
import { LsCsvRowItem, LsErcotCsvRowItem } from '../types'
import LSPodList from './LSPodList'

const StyledActionableSection = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  align-items: flex-end;
`

const StyledBadge = styled(IWGeneralBadge)`
  text-transform: none;
`

type StepState = {
  dayAheadDemandBids: LsCsvRowItem[] | LsErcotCsvRowItem[]
  selectedMarket?: string
  selectedFilename?: string
}

export type Props = WizardStepProps<StepState>

const FileNameBadge = ({
  filename,
  onIconClick,
}: {
  filename: string | undefined
  onIconClick: () => void
}) => {
  const { t } = useTranslation()
  const title = t('loadScheduling.uploadedFilenameLabel')

  return (
    <IWBadgeGroup>
      {filename ? (
        <StyledBadge
          data-testid="ls-filename-badge"
          label={`${title}: ${filename || 'No file selected'}`}
          color="primary"
          icon={faTimes}
          iconPosition="trailing"
          onIconClick={onIconClick}
        />
      ) : (
        <StyledBadge
          data-testid="ls-filename-badge"
          label={`${title}: ${filename || 'No file selected'}`}
          color="primary"
        />
      )}
    </IWBadgeGroup>
  )
}

const LSSelectLoadStep = ({ state, onChange }: Props) => {
  const toast = useToast()

  const { t } = useTranslation()

  const filesRef = useRef<HTMLInputElement | null>(null)
  const [selectedFilename, setSelectedFilename] = useState<string | undefined>(
    state?.selectedFilename || undefined,
  )
  const [, setHasUploadFileSelected] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [selectedPodIds, setSelectedPodIds] = useState<string[]>([])
  const [hasErrors, setHasErrors] = useState(false)
  const [currentState, setCurrentState] = useState<
    LsCsvRowItem[] | LsErcotCsvRowItem[]
  >(state?.dayAheadDemandBids || [])

  const [selectedNetOpenPosition, setSelectedNetOpenPosition] = useState<Pod>()
  const [netOpenPositionPods, setNetOpenPositionPods] = useState<Pod[]>()
  const [selectedMarket, setSelectedMarket] = useState<string | undefined>()
  const [lsPods, setLsPods] = useState<Pod[]>([])

  const toastOptions = {
    canDismiss: true,
    duration: 10_000,
  }

  const { state: queryState, setParam } = useBoundQueryParams({})

  const { data: pods, isLoading: isLoadingPods } = useQuery(
    ['shortTermForecast-ls-submit'],
    () => getPodList(0, 100, '', ['shortTermForecast', 'riskManagement']),
  )

  const handleOnUpload = async (files: File[]) => {
    try {
      if (files.length === 0) {
        return
      }

      const [file] = files
      const { name: fileName } = file

      const lsJson = await convertLsCsvToJson(files)
      const isErcot = isErcotMarket(
        lsJson as LsCsvRowItem[] | LsErcotCsvRowItem[],
      )

      if (isErcot) {
        setSelectedMarket('ercot')
      } else if (lsJson.length > 0) {
        setSelectedMarket(lsJson[0].market)
      } else {
        setSelectedMarket(undefined)
      }

      setSelectedPodIds([])
      setCurrentState(lsJson)
      setHasUploadFileSelected(true)
      setHasErrors(false)

      setSelectedFilename(fileName)

      toast.grey(t('loadScheduling.toasts.uploadSuccessful'), {
        icon: faCheck,
        description: fileName,
        canDismiss: true,
        duration: 5_000,
        actionButtons: [
          {
            text: '',
            action: () => {},
          },
        ],
      })
    } catch (e) {
      setHasErrors(true)
      setCurrentState([])
      setSelectedPodIds([])
      setSelectedMarket(undefined)
      setHasUploadFileSelected(false)
      setSelectedFilename(undefined)

      toast.alert(t('errors.generic.processing'), toastOptions)
      console.error(e)
    }
  }

  const handleOnDownload = async ({
    datasource = '',
    userPodId,
    userPodName,
    markets,
  }: Pod) => {
    try {
      const [currentMarket] = markets

      setCurrentState([])
      setIsDownloading(true)
      setHasUploadFileSelected(false)

      setSelectedPodIds([userPodId])
      setSelectedMarket(currentMarket)

      const marketTimezone = TIMEZONES[currentMarket.toUpperCase()]

      const lsData = await getLsData(
        datasource,
        marketTimezone,
        markets,
        selectedNetOpenPosition,
      )

      const csv = convertToCVS(
        lsData.updatedData,
        currentMarket === 'ercot'
          ? getErcotDayAheadBidsConvertSchema().map(({ accessor }) => accessor)
          : getDayAheadBidsConvertSchema().map(({ accessor }) => accessor),
      )

      if (!csv) {
        toast.warning(t('loadScheduling.convertWarningMessage'), toastOptions)
        setIsDownloading(false)
        return
      }

      if (lsData.hasMissingMappings) {
        toast.warning(t('loadScheduling.toasts.missingMappingsFoundTitle'), {
          icon: faExclamationCircle,
          description: t('loadScheduling.toasts.missingMappingsFoundDesc'),
          canDismiss: true,
          duration: 15_000,
          actionButtons: [
            {
              text: '',
              action: () => {},
            },
          ],
        })
      }

      downloadContent({
        filename: `${userPodName}-${userPodId}`,
        content: csv,
        ext: 'csv',
        mediaType: 'text/csv',
      })
    } catch (e) {
      setHasErrors(true)
      setCurrentState([])
      setSelectedMarket(undefined)
      setSelectedPodIds([])

      toast.alert(t('errors.generic.processing'), toastOptions)
      console.error(e)
    } finally {
      setIsDownloading(false)
      setSelectedFilename(undefined)
    }
  }

  const handleOnSelect = async ({
    datasource = '',
    userPodId,
    markets,
  }: Pod) => {
    try {
      const [currentMarket] = markets

      if (userPodId === selectedPodIds[0]) {
        return
      }

      if (selectedNetOpenPosition === undefined && currentMarket === 'ercot') {
        toast.warning(t('loadScheduling.toasts.pleaseSelectNOP'), {
          canDismiss: true,
          duration: 5_000,
        })
        return
      }

      setCurrentState([])
      setIsDownloading(true)

      setSelectedPodIds([userPodId])
      setSelectedMarket(currentMarket)

      if (queryState?.podId !== userPodId) {
        setParam('podId', userPodId)
      }

      const marketTimezone = TIMEZONES[currentMarket.toUpperCase()]
      const lsData = await getLsData(
        datasource,
        marketTimezone,
        markets,
        selectedNetOpenPosition,
      )

      setCurrentState(lsData.updatedData)
      setHasErrors(false)

      if (lsData.hasMissingMappings) {
        toast.warning(t('loadScheduling.toasts.missingMappingsFoundTitle'), {
          icon: faExclamationCircle,
          description: t('loadScheduling.toasts.missingMappingsFoundDesc'),
          canDismiss: true,
          duration: 15_000,
          actionButtons: [
            {
              text: '',
              action: () => {},
            },
          ],
        })
      }
    } catch (e) {
      setCurrentState([])
      setSelectedPodIds([])
      setSelectedMarket(undefined)
      setHasErrors(true)

      toast.alert(t('errors.generic.processing'), toastOptions)
      console.error(e)
    } finally {
      setIsDownloading(false)
      setSelectedFilename(undefined)
      setHasUploadFileSelected(false)
    }
  }

  const clearInputFiles = (): void => {
    if (filesRef?.current) {
      filesRef.current.value = ''
    }

    setSelectedFilename(undefined)
    setCurrentState([])
  }

  const onFileSelect = (event: ChangeEvent<HTMLInputElement>): void => {
    const files: File[] = event && Array.from(event.target.files || [])
    const hasFiles = Boolean(files?.length > 0)

    if (!hasFiles) {
      clearInputFiles()
    }

    handleOnUpload(files)
  }

  const handleOnSelectNetOpenPositions = (
    selectedNetOpenPositionsPod: Pod | undefined,
  ) => {
    setSelectedNetOpenPosition(selectedNetOpenPositionsPod)
  }

  useEffect(() => {
    onChange(
      {
        dayAheadDemandBids: currentState,
        selectedMarket,
        selectedFilename,
      },
      !hasErrors && currentState.length > 0,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasErrors, currentState])

  useEffect(() => {
    if (pods) {
      let nopPods: Pod[] = []
      let stfPods: Pod[] = []

      for (const pod of pods.rows) {
        if (pod.podType.metricId === 'netOpenPosition') {
          nopPods.push(pod)
        } else if (pod.podType.insightId === 'shortTermForecast') {
          stfPods.push(pod)
        }
      }
      setNetOpenPositionPods(nopPods || [])
      setLsPods(stfPods || [])
    }
  }, [pods])

  useEffect(() => {
    if (queryState?.podId) {
      const pod = lsPods.find((p) => p.userPodId === queryState.podId)

      if (pod) {
        handleOnSelect(pod)
      }
    }
  }, [queryState])

  return (
    <>
      <div style={{ marginBottom: '0rem' }}>
        <IWFormSection
          sectionTitle={t('loadScheduling.sections.uploadTitle')}
          sectionDescription={t('loadScheduling.sections.uploadDescription')}
        >
          <FileNameBadge
            filename={selectedFilename}
            onIconClick={clearInputFiles}
          />

          <StyledActionableSection>
            <div style={{ display: 'none' }}>
              <IWFileInput
                ref={filesRef}
                type="file"
                name="file"
                accept=".csv"
                onChange={onFileSelect}
              />
            </div>

            <IWButton
              variant="outline"
              onClick={() => filesRef.current?.click()}
            >
              {t('button.upload')}
            </IWButton>
          </StyledActionableSection>
        </IWFormSection>
      </div>

      <IWContextDivider />

      <IWFormSection
        sectionTitle={t('loadScheduling.sections.selectPodTitle')}
        sectionDescription={t('loadScheduling.sections.selectPodDescription')}
      >
        {isLoadingPods && <IWLoading />}
        {!isLoadingPods && lsPods.length === 0 && <IWNoResultsMessage />}

        {lsPods && lsPods.length > 0 && (
          <LSPodList
            pods={lsPods.map((p) => ({
              ...p,
              currentStatus:
                isDownloading && p.userPodId === selectedPodIds[0]
                  ? 'running'
                  : 'complete',
              currentStatusMessage: t(`placeholders.loading`),
            }))}
            isLoading={isDownloading}
            onDownload={handleOnDownload}
            onSelect={handleOnSelect}
            onNetOpenPositionSelected={(pod) =>
              handleOnSelectNetOpenPositions(pod)
            }
            netOpenPositionList={netOpenPositionPods}
            selectedPodIds={selectedPodIds}
            selectedNetOpenPosition={selectedNetOpenPosition}
          />
        )}
      </IWFormSection>
    </>
  )
}

export default LSSelectLoadStep
